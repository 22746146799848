<template>
	<div>
		<multiselect
			v-model="selected"
			:options="options"
			label="text"
			track-by="value"
			:placeholder="$t('select')"
			:close-on-select="true"
			:clear-on-select="true"
			:select-label="''"
			:selected-label="''"
			:deselect-label="''"
			@input="handleInput($event)"
		>
			<span slot="noOptions">
                {{ $t('no_options') }}
            </span>
			<span slot="noResult">
                {{ $t('no_result') }}
            </span>
		</multiselect>
		<span class="invalid-feedback-custom" v-show="validateError" v-html="validateError"></span>
	</div>
</template>

<script>
	// Helpers
	import handleInput from "@/helpers/handleInput";
	import setSelected from "@/helpers/setSelected";
	
	export default {
		props: {
			value: {
				type: String,
				default: null
			},
			validateError: {
				type: String,
				default: ''
			}
		},
		watch: {
			value: function (newValue) {
				this.selected = this.setSelected(newValue, this.options)
			},
		},
		created() {
			this.selected = this.setSelected(this.value, this.options)
		},
		data() {
			return {
				selected: null,
				options: [
					{value: 'L', text: this.$t('program_level_l')},
					{value: 'OL', text: this.$t('program_level_ol')},
					{value: 'YL', text: this.$t('program_level_yl')}
				]
			}
		},
		methods: {
			handleInput: handleInput,
			setSelected: setSelected,
		}
	}
</script>
